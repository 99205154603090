import * as React from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import Sheet from '@mui/joy/Sheet';
import { closeSidebar } from '../../utils/drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketShopping, faClipboardList, faHeart, faHouse, faIndustry, faListCheck, faMountainSun } from '@fortawesome/free-solid-svg-icons';
import LoggedUserContainer from './LoggedUserContainer';
import ColorSchemeToggle from './ColorSchemeToggle';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, producerStore } from '../../store';
import { observer } from 'mobx-react-lite';
import { faRectangleList } from '@fortawesome/free-regular-svg-icons';


const menu = [
  {
    label: 'Inicio',
    icon: faHouse,
    route: '/',
  },
  {
    label: 'Cadastrar produtor',
    icon: faHeart,
    route: '/produtor/cadastrar',
    isAuthenticated: true,
  },
  {
    label: 'Produtores',
    icon: faIndustry,
    route: '/produtores'
  },
  {
    label: 'Meus pedidos',
    icon: faListCheck,
    route: '/meus-pedidos',
    isAuthenticated: true,
  },
]

const producerMenu = [
  {
    label: 'Inicio',
    icon: faMountainSun,
    route: '/produtor',
  },
  { 
    label: 'Catálogo',
    icon: faRectangleList,
    route: '/produtor/gerenciar/catalogo',
  },
  {
    label: 'Dados',
    icon: faClipboardList,
    route: '/produtor/dados'
  }
]

const Drawer = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const onMenuItemClick = (route) => {
    closeSidebar()
    if( location.pathname !== route )  navigate(route)
  }

  
  return (
    <>
      <Box
        className="SecondSidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          bgcolor: 'background.body',
          opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Sheet
        className="SecondSidebar"
        sx={{
          position: {
            xs: 'fixed',
            lg: 'sticky',
          },
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'none',
          },
          borderRight: '1px solid',
          borderColor: 'divider',
          transition: 'transform 0.4s',
          zIndex: 9999,
          height: '100dvh',
          top: 0,
          p: 2,
          py: 3,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <List
          sx={{
            '--ListItem-radius': '8px',
            '--ListItem-minHeight': '32px',
            '--List-gap': '4px',
          }}
        >
          <ListSubheader role="presentation" sx={{ color: 'text.primary' }}>
            Connect Roça  
          </ListSubheader>
          
          {
            menu.map( item => {
                if(item.isAuthenticated && !auth.isAuthenticated ) return null

                return <ListItem key={item.route} >
                  <ListItemButton onClick={() => onMenuItemClick(item.route)}>
                    <ListItemDecorator>
                      <FontAwesomeIcon icon={item.icon} />
                    </ListItemDecorator>
                    <ListItemContent>{item.label}</ListItemContent>
                  </ListItemButton>
                </ListItem>
              }
            )
            
          }

          <ListItem  >
            <ListItemButton color="primary" variant="solid" onClick={() => onMenuItemClick('/carrinho')}>
              <ListItemDecorator style={{color:'white'}} >
                <FontAwesomeIcon icon={faBasketShopping} />
              </ListItemDecorator>
              <ListItemContent>Cesta</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem  >
            <ListItemButton color="danger" variant="solid"  onClick={() => onMenuItemClick('/favoritos')}>
              <ListItemDecorator style={{color:'white'}} >
                <FontAwesomeIcon icon={faHeart} />
              </ListItemDecorator>
              <ListItemContent>Favoritos</ListItemContent>
            </ListItemButton>
          </ListItem>
            
          { 
           producerStore.isProducer ?   
           <>
              <ListSubheader role="presentation" sx={{ color: 'text.primary' }}>
                Produtor Rural
              </ListSubheader>
              
                {
                  producerMenu.map( item =>
                    <ListItem key={item.route} >
                      <ListItemButton  onClick={() => onMenuItemClick(item.route)}>
                        <ListItemDecorator>
                          <FontAwesomeIcon icon={item.icon} />
                        </ListItemDecorator>
                        <ListItemContent>{item.label}</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  )
                }
              
            </>
            :
            <></>
          }
        </List>
        <Box sx={{ pl: 1, mt: 'auto', display: 'flex', alignItems: 'center' }}>
          <LoggedUserContainer />
        </Box>
        <Box sx={{ display: 'flex', width: '1' }} >
          <ColorSchemeToggle />
        </Box>
      </Sheet>
    </>
  );
})

export default Drawer
