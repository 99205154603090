import { CircularProgress, Grid, Typography } from "@mui/joy";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import ProducerCard from './../components/cards/ProducerCard'

export default function ProducersPage(){
    const { response } = useLoaderData()

    const [producers, setProducers] = useState(null)

    response.then( res => setProducers(res.data) )

    if(!producers){
        return <Grid container justifyContent="center" alignItems="center" minHeight="90%" >
            <CircularProgress size="lg" />
        </Grid>
    }

    return <Grid container spacing={0.4} >
        <Grid xs={12} >
            <Typography level="h4" > Produtores </Typography>
        </Grid>
        {
            producers.map( producer => 
                <Grid xs={12} md={4} key={producer._id} > <ProducerCard producer={producer} /> </Grid>
            )
        }
    </Grid>
}