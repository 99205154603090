import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid, IconButton, Typography } from "@mui/joy";
import { toDecimal } from "../helpers/number";
import PriceLabel from "./PriceLabel";
import MeasureLabel from "./MeasureLabel";

export default function CartCard({ item, onRemoveCart = ()=>{} }){
   
    return <Card variant="outlined" >
        <Grid container justifyContent="center" >
            <Grid xs={12} >
                <Grid spacing={1} container justifyContent="space-between" >
                    <Typography level="h6"> <Typography color="primary" fontWeight="lg" fontSize="lg" > {item.quantity}x </Typography> {item.product.name} </Typography>
                    <Grid>
                        <IconButton onClick={onRemoveCart} size="sm" color="danger" > <FontAwesomeIcon icon={faTrashCan} /> </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={6} >
                <PriceLabel price={toDecimal(item.total)} />
            </Grid>  
            <Grid xs={6} >
                <MeasureLabel measure={item.product.measure} />  
            </Grid>
         
        </Grid>
    </Card>
}