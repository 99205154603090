import { useState } from "react";
import { Box, Chip, Grid, ListItemDecorator, Tab, TabList, TabPanel, Tabs, Typography, tabClasses } from "@mui/joy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAppleWhole, faHeartBroken, faIndustry } from "@fortawesome/free-solid-svg-icons";
import ProductCard from "../components/cards/ProductCard";
import { useLoaderData } from "react-router-dom";
import ProducerCard from "../components/cards/ProducerCard";
import { observer } from "mobx-react-lite";
import { favorite } from "../store";

const FavoritePage = observer(() => { 
    

    return <Tabs aria-label="Basic tabs" defaultValue={0} sx={{ bgcolor: 'transparent' }} > 
      <TabList >
        <Tab color="primary" >
            <ListItemDecorator>
                <FontAwesomeIcon icon={faAppleWhole} />
            </ListItemDecorator>
            Produtos
        </Tab>
        <Tab  color="primary" >
            <ListItemDecorator>
                <FontAwesomeIcon icon={faIndustry} />
            </ListItemDecorator>
            Produtores
        </Tab>
       
      </TabList>
      <TabPanel value={0}>
        <Grid container spacing={0.5} >
            {
                favorite.favorites?.products?.length > 0 
                ? favorite.favorites?.products?.map( product => 
                    <Grid xs={12} md={4} key={product._id}  >  
                        <ProductCard product={product} /> 
                    </Grid>
                )
                : <Typography level="title-md" >
                    <FontAwesomeIcon color="gray" icon={faHeartBroken} /> Nenhum Produto favoritado ainda.
                </Typography>
            }
        </Grid>
      </TabPanel>
      <TabPanel value={1}>
        <Grid container spacing={0.5} >
            {
                favorite.favorites?.producers?.length > 0
                ? favorite.favorites?.producers?.map( producer => 
                    <Grid xs={12} md={4} key={producer._id}  >
                        <ProducerCard producer={producer} /> 
                    </Grid>
                )
                : <Typography level="title-md" >
                    <FontAwesomeIcon color="gray" icon={faHeartBroken} /> Nenhum Produtor favoritado ainda.
                </Typography>
            }
        </Grid>
      </TabPanel>
    </Tabs>
})

export default FavoritePage