import axios from 'axios'
import { config } from '../config';

const client = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/api`,
    timeout: 3000,
    headers: {
        'Content-Type': 'application/json',
    }
});

export default client


