import { Input } from "@mui/joy"
import ReactInputMask from "react-input-mask"

export function CustomInput({ formState, name, ...attrs }){
    const [form, setForm] = formState

    const inputData = form[name] 

    const onChange = event => {
        const formCopy = Object.assign({},form)

        formCopy[name].value = event.target.value

        setForm(formCopy)
    }

    if(inputData.mask){
        return <ReactInputMask
            mask={inputData.mask}
            value={inputData.value}
            disabled={false}
            maskChar="_"
            onChange={onChange}
        >
            {()=> <Input  {...attrs} />}
        </ReactInputMask>
    }

    return <Input onChange={onChange} value={inputData.value} {...attrs} />    
}

export function CurrencyInput({ formstate, name, ...attrs }){
    const [form, setForm] = formstate

    const inputData = form[name] 

    const onChange = event => {
        const formCopy = Object.assign({},form)

        formCopy[name].value = event.target.value

        setForm(formCopy)
    }

    const currencyMask = "999.999,99"

    return <ReactInputMask
        mask={currencyMask.substring(currencyMask.length - (inputData.value.length + 1 ))}
        value={inputData.value}
        disabled={false}
        maskChar=""
        onChange={onChange}
    >
        {()=> <Input  {...attrs} />}
    </ReactInputMask> 
}