import { IconButton } from "@mui/joy"
import { observer } from "mobx-react-lite"
import { favorite } from "../store"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart as favoritedHeart } from "@fortawesome/free-solid-svg-icons"
import { faHeart as notFavoritedHeart } from "@fortawesome/free-regular-svg-icons"


const FavoriteButton = observer(({ type, object }) => { 
    const handleFavorite = () => {
        favorite.toggle(type, object)
    }

    if(favorite.isFavorited(type, object._id )){ 
        return <IconButton color="danger" onClick={handleFavorite}  variant="soft" size="sm" > <FontAwesomeIcon icon={favoritedHeart} /> </IconButton>
    }
    return <IconButton color="danger" onClick={handleFavorite}  variant="soft" size="sm" > <FontAwesomeIcon icon={notFavoritedHeart} /> </IconButton>
})

export default FavoriteButton