import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Button, Card, FormControl, FormLabel, Grid, Option, Select, Table, Typography } from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";
import { toDecimal } from "../helpers/number";
import PickAddress from "../components/PickAddress";
import { useEffect, useState } from "react";
import { postOrder } from "../requests/order";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { auth, cart } from "../store";


export default function ConfirmOrderPage(){
    const navigate = useNavigate()
    const { state } = useLocation()

    const [address, setAddress] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [isOrderCreated, setIsOrderCreated] = useState(false)

    const cartByProducer = cart.getByProducer()[state.producerId]

    const createOrder = () => {
        if(!auth.isAuthenticated) navigate('/login')

        const addressObj = Object.assign({}, address)
        
        if(!address || !paymentMethod ) return setAlert(true)

        setIsLoading(true)

        const producerId = state.producerId

        const productsFormated = cartByProducer.cart.map( product => ({ 
            product: product.product._id,
            name: product.product.name,
            price: product.product.price,
            quantity: product.quantity,
        }))

        postOrder({ address: addressObj, paymentMethod, products: productsFormated, producerId })
            .then(res =>{
                setIsOrderCreated(true);
                cart.clearByProducer(producerId)
            })
            .catch( err =>{
                setAlert(true)
            }).finally( () =>{
                setIsLoading(false)
            })
    }

    if(isOrderCreated){
        return <Grid container justifyContent="center" >
            <Grid xs={12} md={4} >
                <Card variant="outlined" > 
                    <Grid container alignSelf="center" direction="column"  >
                        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 50, color: 'green' }}  />
                        <Box mt={3} ></Box>
                        <Typography fontSize="lg" > Pedido enviado com sucesso! </Typography>
                        <Button variant="plain" color="success" >Acompanhar pedido</Button>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    }

    return <Grid container spacing={0.8} >
        <Grid xs={12} mb={1} > <Typography level="h3" > Confirmar do pedido </Typography> </Grid>
        {
            alert !== null 
            ? <Grid>
                <Alert variant="soft" color="danger"  > 
                    Necessário preencher Endereço e meio de pagamento.
                </Alert>
            </Grid>
            : null
        }
        <Grid xs={12} md={6} >
            <Card variant="outlined" >
                <Grid xs={12} > 
                    <Typography level="h5" > 
                        <FontAwesomeIcon icon={faReceipt} /> 
                        &nbsp; Resumo 
                    </Typography> 
                </Grid>
                <Grid xs={12} > 
                    <Table >
                        <thead>
                            <tr>
                                <th>Qtd.</th>
                                <th>Produto</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cartByProducer.cart.map( item =>{
                                    return <tr key={item._id} >
                                        <td>{item.quantity}</td>
                                        <td> {item.product.name}({item.product.measure}) </td>
                                        <td>{toDecimal(item.total)}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </Grid>
            </Card>
        </Grid>
        <Grid xs={12} md={6} >
            <PickAddress from="Endereço de entrega" startOpened onComplete={setAddress} withSaveButton={false} withToggleButton={false} />       
        </Grid>
        <Grid xs={12} md={6} mb={2} >
            <Card variant="outlined" >
                <FormControl>
                    <FormLabel  >Escolher método de pagamento</FormLabel>
                    <Select onChange={(_,value)=> setPaymentMethod(value)} placeholder="Método de pagamento" >
                        <Option value="pix" > Pix </Option>
                        <Option value="creditCard" > Cartão de crédito </Option>
                        <Option value="debitCard" > Cartão de débito </Option>
                        <Option value="money" > Dinheiro </Option>
                        <Option value="bankSlip" > Boleto </Option>
                    </Select>
                </FormControl>

                
                <Typography sx={{ mt: 3 }} level="h5" > 
                    Subtotal: { toDecimal(cartByProducer.subtotal) } <br />
                    Frete: R$ 7,0
                </Typography> 
                <Typography level="h4" > 
                    Total: { toDecimal(cartByProducer.subtotal + 700) }
                </Typography> 

                <Button sx={{mt: 1}} fullWidth onClick={createOrder} loading={isLoading} > Finalizar pedido </Button>
            </Card>
        </Grid>
    </Grid>
}