import { Box, Card, CardContent, CardCover, Typography } from "@mui/joy";
import theme from "../../theme";

export default function CategoryCard({ category, categoryGroup, onClick }){ 
    return <Box  sx={{
            perspective: '1000px',
            transition: 'transform 0.4s',
            '& > div, & > div > div': {
            transition: 'inherit',
            },
            '&:hover': {
            '& > div': {
                transform: 'rotateY(30deg)',
            },
            },
        }}>
        
            <Card 
                onClick={onClick}
                sx={{ 
                    minHeight: '160px', 
                    width: 175, 
                    [theme.breakpoints.up('md')]: { 
                        width: 180,
                    }, 
                    [theme.breakpoints.up('lg')]: { 
                        width: 210,
                    },
                }}  
            >
            <CardCover>
                <img
                    src={`product-category/${category}.jpg?auto=format&fit=crop&w=320`}
                    srcSet={`product-category/${category}.jpg?auto=format&fit=crop&w=320&dpr=2 2x`}
                    loading="lazy"
                    alt=""
                />
            </CardCover>
            <CardCover
                sx={{
                background:
                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 160px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 160px)',
                }}
            />
            <CardContent sx={{ justifyContent: 'flex-end' }}>
                <Typography level="h2" fontSize="md" textColor="#fff" mb={0.5}>
                    {category}
                </Typography>
                {
                    categoryGroup && <Typography fontSize="sm" textColor="neutral.300" >
                        {categoryGroup}
                    </Typography>
                }
            </CardContent>
        </Card>
    </Box>
}