import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CircularProgress, Grid, IconButton, Input, Option, Select, Typography } from "@mui/joy";
import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getProducts } from "../../../../requests/producer";
import { producerStore } from '../../../../store';
import ProducerProductCard from "./ProducerProductCard";
import { selectCategoryOptions } from "../../../../components/selectCategoryOptions";
import { measureUnits } from "../../../../helpers/measure";


export default function ManagementCatalogPage(){
    const navigate = useNavigate()

    const {response} = useLoaderData()
    
    const [products, setProducts] = useState(null)

    const [nameFilter, setNameFilter] = useState(null);
    const [categoryFilter, setCategoryFilter] = useState(null);
    const [measureFilter, setMeasureFilter] = useState(null);

    response.then(res => {
        if(products === null) setProducts(res.data);
    })

    const update = () => { 
        getProducts(producerStore.producer._id).then( res =>{
            setProducts(res.data)
        })
    }
    
    const filteredProducts = () => 
        nameFilter || categoryFilter || measureFilter 
        ? products.filter((product) => 
            (nameFilter ? product.name.toLowerCase().includes(nameFilter.toLowerCase()) : true)
            && (categoryFilter ? product.category === categoryFilter : true)
            && (measureFilter ? product.measure === measureFilter : true) 
        )
        : products;

    if(products !== null)
        return <Grid container  >
            <Grid xs={12} >
                <Card variant="outlined"  >
                    <Typography level="h5" fontSize="md" fontWeight="lg" > Filtros </Typography>
                    <Grid container spacing={0.5} >
                        <Grid xs={5} md={4} lg={2}  >
                            <Button onClick={()=> navigate('/produtor/produto/cadastrar')} size="sm" color="success" variant="solid" fullWidth startDecorator={<FontAwesomeIcon icon={faPlus} />}  >  
                                Adicionar Produto
                            </Button>
                        </Grid>
                        <Grid xs={7} md={4} lg={2} >
                            <Select placeholder="Categoria" size="sm" onChange={ (_,value) => setCategoryFilter(value) } >
                                <Option value={null} >Todos</Option>
                                {selectCategoryOptions()} 
                            </Select>
                        </Grid>
                        <Grid xs={6} md={4} lg={2} >
                            <Select placeholder="Medida" size="sm" onChange={(_,value)=> setMeasureFilter(value)} >
                                <Option value={null} >Todos</Option> 
                                {
                                    Object.keys(measureUnits).map( 
                                        key => <Option key={key} value={key} >
                                            {measureUnits[key]}({key})
                                        </Option>
                                    )
                                }
                            </Select>
                        </Grid>
                        <Grid xs={6} md={2} >
                            <Input placeholder="Nome" size="sm" onChange={(event)=> setNameFilter(event.target.value) } />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            {filteredProducts().map( product => 
                <Grid key={product._id} sm={12} md={4} lg={4} padding={1} >
                    <ProducerProductCard  product={product} onRemove={update} />
                </Grid>
            )}
        </Grid>

    return <Grid container justifyContent="center" alignItems="center" spacing={1} >
        <Grid>
            <CircularProgress size="lg" />
        </Grid>
    </Grid>
}