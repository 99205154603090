import { Button, Grid, Typography } from "@mui/joy";
import { observer } from "mobx-react-lite";
import CartCard from "../components/CartCard";
import { toDecimal } from "../helpers/number";
import PriceLabel from "../components/PriceLabel";
import { useLoaderData, useNavigate } from "react-router-dom";
import { auth, cart } from "../store";
import { useEffect, useState } from "react";
import { getProducerById } from "../requests/producer";


const CartPage = observer(() =>{
    const navigate = useNavigate()    
    
    const [byProducerCart, setByProducerCart] = useState(null)

    const onRemoveCart = (cartId) =>{ 
        cart.removeProduct()
    }

    useEffect(()=>{ 
        setByProducerCart(cart.getByProducer())
    },[cart.cart])

    const  getProducer = (producerId) => { 
        getProducerById(producerId).then( res =>{
            const copy = Object.assign({}, byProducerCart)

            copy[producerId].cart[0].product.producer = res.data

            setByProducerCart(copy)
        })
    }

    const toConfirmPage = (producerId ) => {
        navigate('/pedido/confirmar',{ state: { producerId  } })
    }

    if( byProducerCart === null) return <></>

    return <> 
        <Grid container spacing={0.5} >
            <Grid xs={12} > <Typography level="h3" >Cesta</Typography> </Grid>
        </Grid>
        {
            Object.keys(byProducerCart).map( 
                producerId => <Grid key={producerId} container spacing={0.5} >
                    <Grid xs={12} > 
                        <Typography level="h3" > 
                            {byProducerCart[producerId].cart[0].product.producer?.name || getProducer(byProducerCart[producerId].cart[0].product.producer) } 
                        </Typography>
                    </Grid>
                    {
                        byProducerCart[producerId].cart.map( 
                            item => <Grid key={item._id} xs={12} md={4} lg={3} > 
                                <CartCard item={item} onRemoveCart={()=> onRemoveCart(item.product.id)} /> 
                            </Grid>
                        )
                    }
                    <Grid xs={12} mt={2} >
                        <Grid container justifyContent="center" > 
                            <Button onClick={() => toConfirmPage(producerId)} >Fechar pedido R$ {toDecimal(byProducerCart[producerId].subtotal)} </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    </>
})

export default CartPage