import { Button, Card, Chip, Grid, Typography } from "@mui/joy";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { measureUnits } from "../../helpers/measure";
import { toDecimal } from "../../helpers/number";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import QuantityInput from "../../components/QuantityInput";
import { getProduct } from "../../requests/catalog";
import { deleteCart, postCart } from "../../requests/cart";
import { auth, cart } from "../../store";
import PriceLabel from "../../components/PriceLabel";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";


export default function CatalogProductPage(){
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const [product, setProduct] = useState()
    const [quantity, setQuantity] = useState(1)

    const [thisProductOnCart, setThisProductOnCart] = useState(null)
    const [isRemoveCartLoading, setIsRemoveCartLoading] = useState(false)

    useEffect(()=>{
        if(location.state?.product) setProduct(location.state.product )
        else getProduct(params.productId).then( res =>{
                setProduct(res.data)
            })
        
    },[location])

    useEffect(()=>{
        if(auth.isAuthenticated && product ) setThisProductOnCart(cart.getProduct(product._id))
    },[product])

    const addToCart = () =>{
        const aggregateQuantity = thisProductOnCart != undefined ? quantity + thisProductOnCart.quantity : quantity
        
        postCart(product._id, aggregateQuantity).then(res =>{
            setQuantity(1)
            auth.updateCart(res.data)
            setThisProductOnCart(res.data.find( cartItem => cartItem.product._id === product._id ))
        })
    }

    const removeToCart = () => {
        setIsRemoveCartLoading(true)

        cart.removeProduct(thisProductOnCart?.product?._id).finally( res => {  
            setIsRemoveCartLoading(false)
        });
    }  


    if(!product) return <></>

    return <Grid container spacing={1} >
        <Grid xs={12} > 
            <Typography level="h2" > {product.name} </Typography> 
        </Grid>
        {
            product?.producer?._id 
            ? <Grid xs={12} mb={1} > 
                <Typography  level="h3" fontSize="md" >
                    <Chip onClick={()=> navigate(`/catalogo/${product.producer._id}/produtor`)} size="sm" variant="soft" endDecorator={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}  > {product.producer.name} </Chip>
                </Typography>
            </Grid>
            : null
        }
        <Grid xs={12} > 
            <Typography level="h6" > {product.category} </Typography> 
        </Grid>
        <Grid xs={6} md={3} >
            <Typography fontSize="md" fontWeight="lg">
                R$ {toDecimal(product.price)}
            </Typography>
        </Grid>
        <Grid xs={6} md={9} >
            <Typography level="h6" > {measureUnits[product.measure]}({product.measure}) </Typography>
        </Grid>
        <Grid xs={12} md={6} >  
            <Card variant="outlined" >
                <Typography level="body1" >Quantidade:</Typography>
                <Grid container justifyContent="center" mb={1} > 
                    <Typography fontSize="lg" fontWeight="lg" >Valor: {toDecimal(product.price*quantity)} </Typography> 
                </Grid>
                <Grid xs={12} >
                    <QuantityInput quantityUpdater={setQuantity} quantity={quantity} />
                </Grid>
                <Grid xs={12} mt={2} > 
                    <Button onClick={addToCart} fullWidth startDecorator={<FontAwesomeIcon icon={faBasketShopping} />} > 
                        Adicionar na cesta
                    </Button>
                </Grid>
                {
                    thisProductOnCart 
                    ? <Grid xs={12} mt={1} >
                        <Typography > Já tem esse item na sua cesta... </Typography>
                        <Card variant="outlined" >
                            <Grid container  justifyContent="space-around" >
                                <Grid xs={6} mb={1} >
                                    <Typography level="h5" > Qtd. {thisProductOnCart.quantity} </Typography>
                                </Grid>
                                <Grid xs={6} >
                                    <PriceLabel level="h5" price={toDecimal(thisProductOnCart.quantity * thisProductOnCart.product.price)} />
                                </Grid>
                                <Button onClick={()=> navigate('/carrinho')} size="sm" >
                                    Abrir cesta
                                </Button>
                                <Button startDecorator={<FontAwesomeIcon icon={faTrashAlt} />} loading={isRemoveCartLoading} size="sm" color="danger" variant="soft" onClick={removeToCart}>
                                    Remover
                                </Button>
                            </Grid>
                        </Card>
                    </Grid>
                    : null
                }
            </Card>
        </Grid>
        <Grid xs={12} md={6} >
            <Card variant="outlined" >
                <Typography level="h5" >Descrição:</Typography>
                <Typography> {product.description} </Typography>
            </Card>
        </Grid>
    </Grid>
}