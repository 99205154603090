import { Box, Card, Chip, Divider, FormControl, FormLabel, Grid, IconButton, Input, List, ListDivider, ListItem, ListItemContent, Typography } from "@mui/joy";
import PickAddress from "../../components/PickAddress";
import { putProducerAddress } from "../../requests/producer";
import { producerStore } from "../../store";
import { observer } from "mobx-react-lite";
import { faPencil, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toBrDateFromString } from "../../helpers/date";
import { useState } from "react";
import ProducerDataCard from "./components/ProducerDataCard";


const labelAndValue = (label, value) => <> <Grid xs={4} > <Typography mr={4} fontWeight="lg" >{label}</Typography> </Grid> <Grid xs={8} > <Typography> {value} </Typography> </Grid> </>

const ProducerDataPage = observer(()=>{
    const { producer } = producerStore

    const [isEditing, setIsEditing] = useState()


    return <Grid container >
        <Grid xs={12} md={6} mb={1} >
            <ProducerDataCard />
        </Grid>
        <Grid xs={6} ></Grid>
        <Grid xs={12} md={6} padding={0.3} >
            <PickAddress 
                from="Endereço Rural" 
                onSave={async (data)=>{
                    data.targetAddress = 'rural'

                    const result = await putProducerAddress(producerStore.producer._id, data);

                    producerStore.refresh()

                    return result
                }}
                currentAddress={producerStore.producer.ruralAddress}
            />    
        </Grid>
        <Grid xs={12} md={6} padding={0.3} >
            <PickAddress 
                from="Endereço Urbano"
                onSave={async (data)=>{
                    data.targetAddress = 'urbano'

                    const result = await putProducerAddress(producerStore.producer._id, data);

                    producerStore.refresh()

                    return result
                }}
                currentAddress={producerStore.producer.urbanAddress}
            />
        </Grid>
    </Grid>
})

export default ProducerDataPage 