import { faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/joy";
import { measureUnits } from "../helpers/measure";

export default function MeasureLabel({ measure, level="body1" }){
    return <Typography fontWeight="sm" level={level} >
        <FontAwesomeIcon color="silver" icon={faScaleBalanced} />
        &nbsp;{measureUnits[measure]} 
        <Typography fontSize="sm" >({measure})</Typography>
    </Typography>
}