import { StyledEngineProvider } from '@mui/joy';
import React from 'react'; 
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import './index.css';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RuralProducerRegisterPage from './pages/RuralProducerRegister';
import ProducerHomePage from './pages/producer/ProducerHomePage';
import AddProductPage from './pages/producer/products/registration/AddProductPage';
import ManagementCatalogPage from './pages/producer/products/management/ManagementCatalogPage';
import { producerLoader } from './utils/loaders/producerLoader';
import { getProducts } from './requests/producer';
import HomePage from './pages/home/HomePage';
import ByCategoryPage from './pages/catalog/ByCategoryPage';
import { defaultLoader } from './utils/loaders/defaultLoader';
import { getCatalogByCategory, getProducers, getProduct, getProductsByProducer } from './requests/catalog';
import CatalogProducerPage from './pages/catalog/CatalogProducerPage';
import CatalogProductPage from './pages/catalog/CatalogProductPage';
import ProducerDataPage from './pages/producer/ProducerDataPage';
import CartPage from './pages/CartPage';
import ConfirmOrderPage from './pages/ConfirmOrderPage';
import ProducersPage from './pages/ProducersPage';
import MyOrdersPage from './pages/MyOrders';
import { getOrders } from './requests/order';
import { healthCheck } from './requests/health';
import FavoritePage from './pages/FavoritePage';
import { getSummary } from './requests/producerOrder';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    id: 'root',
    loader:  defaultLoader(healthCheck),
    children: [
      {
        index: true, 
        element: <HomePage /> 
      },
      {
        path: 'catalogo/:category/categoria',
        loader: defaultLoader(getCatalogByCategory,'category'),
        element: <ByCategoryPage />
      },
      {
        path: 'catalogo/:producerId/produtor',
        loader: defaultLoader(getProductsByProducer,'producerId'),
        element: <CatalogProducerPage /> 
      },
      {
        path: 'catalogo/:productId/produto',
        element: <CatalogProductPage />
      },
      {
        path: 'produtores',
        loader: defaultLoader(getProducers),
        element: <ProducersPage />,
      },
      {
        path: 'carrinho',
        element: <CartPage />
      },
      { 
        path: 'favoritos',
        element: <FavoritePage />
      },
      {
        path: 'pedido/confirmar',
        element: <ConfirmOrderPage />,
      },
      {
        path: 'meus-pedidos',
        loader: defaultLoader(getOrders),
        element: <MyOrdersPage />,
      },
      //produtor
      { 
        path: 'produtor/cadastrar',
        element: <RuralProducerRegisterPage /> 
      },
      {
        path: 'produtor',
        loader: defaultLoader(getSummary),
        element: <ProducerHomePage />, 
      },
      { 
        path: 'produtor/produto/cadastrar',
        element: <AddProductPage />,
      },
      { 
        path: 'produtor/gerenciar/catalogo',
        loader: producerLoader(getProducts),
        element: <ManagementCatalogPage />,
      },
      {
        path: 'produtor/dados',
        element: <ProducerDataPage />,
      }
    ]
  },
  {
    path: '/login',
    element: <LoginPage/>,
  },
  {
    path: '/cadastrar',
    element: <RegisterPage/>,
  },
])

ReactDOM.createRoot(document.querySelector('#root')).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst >
      <GoogleOAuthProvider clientId="579527078261-p9tto21e8j1ijkcp4vesrq82eru2rt05.apps.googleusercontent.com" >
        <RouterProvider router={router} />
      </GoogleOAuthProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);


