import { Box, Grid, IconButton, Input, Typography } from "@mui/joy";
import CategoryCard from "./CategoryCard";
import { categoriesList } from "../../helpers/productCategory";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BottomBar from "../../components/layout/BottomBar";


export default function HomePage(){
    const navigate = useNavigate()

    return <Grid container spacing={1} pb={2} justifyContent="center" >
        <Grid xs={12} >
            <Typography level="h3" >O que você deseja?</Typography>
        </Grid>
        <Grid xs={10} >
            <Input placeholder="Diga o produto que tem em mente..." size="md" />
        </Grid>
        <Grid xs={2} >
            <IconButton > <FontAwesomeIcon icon={faSearch} /> </IconButton>
        </Grid>
        {
            categoriesList.map( (category) =>  <Grid key={category} xs={6} md={3} lg={2} > 
                <CategoryCard  category={category} onClick={()=> navigate(`/catalogo/${category}/categoria`,{ state: { category } })} />
            </Grid> 
            )
        }
        
        <BottomBar />
        <Grid height="17rem" ></Grid>
    </Grid>
}