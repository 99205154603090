import { Check } from "@mui/icons-material";
import { Chip, List, ListDivider, ListItem, ListItemDecorator, Option, Typography, listItemDecoratorClasses, optionClasses } from "@mui/joy";
import { Fragment } from "react";
import { categoriesOfProduct } from "../helpers/productCategory";


export function selectCategoryOptions(){ 
   return Object.entries(categoriesOfProduct).map(([name, list], index) => (
        <Fragment key={name}>
        {index !== 0 && <ListDivider role="none" />}
        <List aria-labelledby={`select-group-${name}`} sx={{ '--ListItemDecorator-size': '28px' }} >
            <ListItem id={`select-group-${name}`} sticky>
                <Typography level="body3" textTransform="uppercase" letterSpacing="md">
                    {name} ({list.length})
                </Typography>
            </ListItem>
                {list.map((item) => (
                    <Option key={item} value={item}
                        label={
                        <Fragment>
                            <Chip size="sm" color="primary" sx={{ borderRadius: 'xs', mr: 0.3, ml: -0.5 }} > {name} </Chip>
                            {item}
                        </Fragment>
                        }
                        sx={{
                        [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]:
                            {
                            opacity: 1,
                            },
                        }}
                    >
                        <ListItemDecorator sx={{ opacity: 0 }}>
                            <Check />
                        </ListItemDecorator>
                        {item}
                    </Option>
                ))}
        </List>
        </Fragment>
    ))
}

