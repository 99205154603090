import { CircularProgress, Grid, Typography } from "@mui/joy";
import { useLoaderData, useParams } from "react-router-dom";
import ProductCard from "../../components/cards/ProductCard";
import ProducerCard from "../../components/cards/ProducerCard";
import { useEffect, useState } from "react";


export default function ByCategoryPage(){
    const params = useParams()

    const [products, setProducts] = useState(null)
    const [producers, setProducers] = useState(null)

    const { response } = useLoaderData()

    useEffect(()=>{
        response.then( res =>{
            setProducts(res.data.products)
            setProducers(res.data.producers)
        })
    },[])

    if(producers === null && products === null) return <Grid container justifyContent="center" alignItems="center" spacing={1} >
        <Grid>
            <CircularProgress size="lg" />
        </Grid>
    </Grid>

    return <Grid container >
        <Grid xs={12} mb={1} >
            <Typography level="h3" >Top {products.length} produtos de {params.category} </Typography>
        </Grid>
        <Grid xs={12} container justifyContent="center" >
            {
                products.map( product => 
                    <Grid key={product._id} padding={0.5} xs={12} md={4} >
                        <ProductCard product={product} />  
                    </Grid>  
                )
            }
        </Grid>
        <Grid xs={12} mt={1} >
            <Typography level="h4" > Produtores com essa catêgoria </Typography>
        </Grid>
        <Grid xs={12} container mb={3} >
            {
                producers.map( producer => 
                    <Grid key={producer._id} xs={12} md={4} padding={0.5} >
                        <ProducerCard producer={producer} />
                    </Grid>     
                )
            }
        </Grid>
    </Grid>
}