import { faPencil, faPlus, faSave, faTrashAlt, faWarning } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert, Button, Card, Chip, FormControl, FormLabel, Grid, IconButton, Input, List, ListDivider, ListItem, ListItemContent, Modal, ModalDialog, Option, Select, Stack, Typography } from "@mui/joy"
import { producerStore } from "../../../store"
import { useEffect, useState } from "react"
import { toBrDateFromString } from "../../../helpers/date"
import FormValidator from "../../../services/formValidator.service"
import { deleteProducerContact, postProducerContact, putProducer } from "../../../requests/producer"
import { contactTypes, notRemovableContactTypes } from "../../../helpers/contacts"
import { CustomInput } from "../../../components/standard/CustomInput"
import * as CPF from "gerador-validador-cpf"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"


const getPristineForm = (producer) =>({   
    name: { 
        value: producer?.name || '',
        rules: {
            isFilled: true,
        },
    },
    ruralRegistration: { 
        value: producer?.ruralRegistration || '',
        rules: {
            isFilled: true,
        },
    },
    cpf: { 
        value: producer?.cpf || '',
        mask: '999.999.999-99',
        rules: {
            isFilled: true,
        },
    },
})

const formValidator = new FormValidator()

const ProducerDataCard = () => {
    const { producer } = producerStore

    const [isEditing, setIsEditing] = useState()
    const formState = useState(getPristineForm(producer))
    const [loadingEditProducer, setLoadingEditProducer] = useState()
    
    const [editContact, setEditContact ] = useState(null)
    const [openNewContactModal, setOpenNewContactModal] = useState(false)
    const [contactType, setContactType] = useState(null)
    const [contactValue, setContactValue] = useState('')
    const [loadingContact, setLoadingContact] = useState(false)
    const [loadingDeleteContact, setLoadingDeleteContact] = useState(false) 
    const [errorMessage,setErrorMessage] = useState(null)

    useEffect(()=>{
        formValidator.setFormState(formState)
    },[])

    const editProducer = () => {
        if(!isValidCpf) return setErrorMessage({ msg: 'CPF incorreto!' })

        const validationResult = formValidator.validate()
        
        if(!validationResult){
            setLoadingEditProducer(true)
            putProducer(producer._id, formValidator.sendFormat()).then( res =>{
                producerStore.refresh()
                setIsEditing(false)
            })
            .finally(()=>{
                setLoadingEditProducer(false)
            }) 
        }
        
    }

    const addContact = (isNew, callback) => {
        setLoadingContact(true)
        return postProducerContact(producer._id, isNew ? { key: contactType, value: contactValue } : editContact )
            .then( res =>{
                producerStore.refresh()
                setContactType(null) 
                setContactValue('')
            })
            .finally(res =>{
                setLoadingContact(false)
                callback()
            })
    }
        
    
    const deleteContact = (key, callback) => {
        setLoadingDeleteContact(true)

        deleteProducerContact(producer._id,  key )
            .then( res =>{
                producerStore.refresh()
            })
            .finally( res =>{
                callback()
                setLoadingDeleteContact(false)
            })
    }

    const isValidCpf = CPF.validate(formState[0].cpf.value)

    return <Card variant="outlined" >
        <Grid container justifyContent="space-between" >
            <Typography level="h4" >Dados</Typography>
            <IconButton onClick={()=> setIsEditing(!isEditing)} variant="soft" size="sm" > <FontAwesomeIcon icon={faPencil} /> </IconButton>
        </Grid>
        { errorMessage && <Alert  sx={{ mb: 1 }} color="danger" size="md" variant="soft" startDecorator={<FontAwesomeIcon icon={faWarning}  />} > {errorMessage.msg} </Alert> }  
        <List>
            <ListItem>
                <ListItemContent > Nome </ListItemContent>
                <ListItemContent > 
                    { isEditing 
                        ? <CustomInput  size="sm" name="name" formState={formState} />
                        : producer.name  
                    } 
                </ListItemContent>
            </ListItem>
            <ListItem>
                <ListItemContent  >Registro</ListItemContent>
                <ListItemContent > 
                    { isEditing 
                        ? <CustomInput size="sm"  name="ruralRegistration" formState={formState} />
                        : producer.ruralRegistration
                    } 
                </ListItemContent>
            </ListItem>
            <ListItem>
                <ListItemContent  >CPF</ListItemContent>
                <ListItemContent > 
                    { 
                        isEditing 
                        ? <CustomInput 
                            size="sm" 
                            name="cpf" 
                            formState={formState}
                            endDecorator={
                                isValidCpf
                                ? <FontAwesomeIcon color="green"  icon={faCheckCircle} /> 
                                : <FontAwesomeIcon color="red" icon={faWarning} />
                            } 
                        /> 
                        : producer.cpf
                    } 
                    </ListItemContent>
            </ListItem>
            {
                isEditing && <ListItem>
                    <ListItemContent  > 
                        <Grid container justifyContent="center" > 
                            <Button onClick={editProducer} loading={loadingEditProducer} variant="soft" size="sm" color="success" startDecorator={<FontAwesomeIcon icon={faSave} />}  > Salvar </Button> 
                        </Grid> 
                    </ListItemContent>
                </ListItem>
            }
            
            <ListItem>
                <ListItemContent  >Desde</ListItemContent>
                <ListItemContent > {toBrDateFromString(producer.createdAt)} </ListItemContent>
            </ListItem>
            <ListDivider inset="gutter" /> 
            <ListItem endAction={ <IconButton onClick={()=> setOpenNewContactModal(true)} color="success" size="sm" > <FontAwesomeIcon icon={faPlus} /> </IconButton> } >
                <ListItemContent  >
                    Contatos
                </ListItemContent>
            </ListItem>
            {
                producer.contacts.map( contact =>
                    <ListItem key={contact.key}  endAction={ <IconButton onClick={ ()=> setEditContact(contact) } size="sm" color="primary" > <FontAwesomeIcon icon={faPencil} /> </IconButton> } >
                        <ListItemContent  >{contact.key}</ListItemContent>
                        <ListItemContent > {contact.value} </ListItemContent>
                    </ListItem>    
                )
            }
            <ListDivider inset="gutter" />
            <ListItem>
                <ListItemContent  >Catêgorias</ListItemContent>
            </ListItem>
            <ListItem>
                <ListItemContent  >
                    {
                        producer.expertise.map( category => <Chip key={category} sx={{ margin: 0.1 }} size="sm" variant="outlined" > {category} </Chip> )
                    }
                </ListItemContent>
            </ListItem>
        </List>

        <Modal open={!!editContact} onClose={() => setEditContact(null)}>
            <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            sx={{ maxWidth: 500 }}
            >
                <Typography id="basic-modal-dialog-title" component="h2">
                    Editar ou remover contato
                </Typography>
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>{editContact?.key}</FormLabel>
                        <Input autoFocus required defaultValue={editContact?.value} />
                    </FormControl>
                    <Button  
                        color="success"
                        onClick={ () => addContact(false,()=> setEditContact(null)) }
                        loading={loadingContact}
                    >
                        Salvar
                    </Button>
                    <Button onClick={()=> setEditContact(null)} color="neutral" >Cancelar</Button>
                    {
                        !notRemovableContactTypes.includes(editContact?.key) &&  <Button onClick={()=> deleteContact(editContact.key, ()=> setEditContact(null)) } loading={loadingDeleteContact} color="danger" >Remover contato</Button>
                    }
                </Stack>
            </ModalDialog>
        </Modal>
        <Modal open={openNewContactModal} onClose={() => setOpenNewContactModal(false)}>
            <ModalDialog
                aria-labelledby="basic-modal-dialog-title"
                aria-describedby="basic-modal-dialog-description"
                sx={{ maxWidth: 500 }}
            >
                <Typography id="basic-modal-dialog-title" component="h2">
                    Adicionar contato
                </Typography>
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>Meio de contato</FormLabel>
                        <Select onChange={ (event, value) => setContactType(value)} value={contactType} >
                            {contactTypes.map( contactType => <Option key={contactType} value={contactType} >{contactType}</Option> )}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Contato</FormLabel>
                        <Input autoFocus required value={contactValue} onChange={ event => setContactValue(event.target.value)}  />
                    </FormControl>

                    <Button 
                        onClick={ () => addContact(true, () => setOpenNewContactModal(false)) }
                        color="success"
                        disabled={!contactType || !contactValue}
                        loading={loadingContact}
                    >
                        Salvar
                    </Button>
                    <Button onClick={()=> setOpenNewContactModal(false)} color="neutral" >Cancelar</Button>
                  
                </Stack>
            </ModalDialog>
        </Modal>
    </Card>
}

export default ProducerDataCard