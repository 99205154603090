import axios from 'axios'
import AuthStore from '../store/AuthStore';
import { config } from '../config';

const privateClient = () => axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/api`,
    timeout: 3000,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${AuthStore.getToken()}`,
    }
});

export default privateClient 