import AuthStore from "./AuthStore";
import CartStore from "./CartStore";
import FavoriteStore from "./FavoriteStore";
import ProducerStore from "./ProducerStore";

const auth = new AuthStore();
const producerStore = new ProducerStore();
const favorite = new FavoriteStore()
const cart = new CartStore()

export {
    auth,
    producerStore,  
    favorite,
    cart,
}