import { Box, Chip, CircularProgress, Grid, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useLoaderData, useLocation, useParams } from "react-router-dom";
import ProductCard from "../../components/cards/ProductCard";
import { getProducer } from "../../requests/catalog";

export default function CatalogProducerPage(){
    const location = useLocation()
    const params = useParams()
    
    const [products, setProducts]               = useState(null)
    const [producer, setProducer]               = useState(null)
    const [categoryFilter, setCategoryFilter]   = useState(null)

    const { response } = useLoaderData()

    useEffect(()=>{
        if(producer === null){
            if(location?.state?.producer){
                if( !location?.state?.producer?.expertise.includes('Todos') )
                    location?.state?.producer?.expertise.unshift('Todos')
                
                setProducer(location?.state?.producer)
            } else {
                getProducer(params.producerId).then( res =>{
                    res.data.expertise.unshift('Todos')

                    setProducer(res.data)
                })
            } 
                
        }
        if(products === null) response.then( res => setProducts(res.data) )
    },[])
    
    

    const filteredProducts = categoryFilter && products ? products.filter(product => product.category === categoryFilter) : products 

    return <> <Grid container >
        {
            producer 
            ? <Grid xs={12} >
                <Typography level="h3" >
                    {producer.name}
                </Typography>
            </Grid>
            : <Grid xs={12} container justifyContent="center" >
                <CircularProgress size="lg" />
            </Grid> 
        }
        
        {
            producer
            ? <Grid mb={1} mt={1} > 
                <Box  sx={{ display: 'flex', overflowX: 'auto', padding: 1 }} > 
                    {
                        producer.expertise.map( category => <Box key={category} sx={{mx: 0.2}} >  
                                <Chip variant="outlined"  onClick={() => setCategoryFilter(category === 'Todos' ? null : category )} > 
                                    {category} 
                                </Chip> 
                            </Box> 
                        )  
                    }
                </Box>
            </Grid>
            : <></>
        }
        

        <Grid xs={12} >
           
        </Grid>
    </Grid>
     {
        !!products 
        ? <Grid container spacing={1} >
            {
                filteredProducts.map( product => <Grid xs={12} md={3} lg={3} key={product._id}  > 
                        <ProductCard product={product} /> 
                    </Grid> 
                )
            }
        </Grid>
        : <Grid container justifyContent="center" >
            <CircularProgress size="lg" />
        </Grid> 
    }
    </>
}
