export const measureUnits = {
    kg: 'quilograma',
    g: 'grama',
    t: 'tonelada',
    l: 'litro',
    m3: 'metro cúbico',
    uni: 'unidade',
    cx: 'caixa',
    dz: 'dúzia',
    arroba: 'arroba',
    sc: 'sacas',
    t: 'tonelada métrica',
    pt: 'pote',
    fd: 'fardo',
}