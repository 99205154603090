import { Box } from "@mui/joy"

export default function ColorizedDot({ color }){ 
    return <Box
        component="span"
        sx={{
        mr: 1,
        mb: 1, 
        bgcolor: (color + '.400' ) || 'neutral.400',
        width: '0.6em',
        height: '0.6em',
        borderRadius: '50%',
        }}
    /> 
}