import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Grid, IconButton, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toDecimal } from "../../../../helpers/number";


export default function ProducerProductCard({product, onRemove}){
    const navigate = useNavigate() 

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isDeleteloading, setIsDeleteLoading] = useState(false)

    const handleDelete = () => {
        setIsDeleteLoading(true); 

        deleteProduct( producerStore.producer._id, product._id )
            .finally(()=>{
                setIsDeleteLoading(false)
                setDeleteModalOpen(false)
            })
            .then(onRemove)
    }

    return <Card variant="outlined" sx={{ width: '100%' }}  >
        <Grid container >
            <Box sx={{ position: 'absolute', top: '0.3rem', right: '0.3rem' }} >
                <IconButton onClick={() => navigate('/produtor/produto/cadastrar',{ state: { editMode: true, product } }) }  size="sm" sx={{ mr: 0.5 }} > 
                    <FontAwesomeIcon icon={faPencil} />
                </IconButton>
                <IconButton onClick={()=> setDeleteModalOpen(true)} size="sm" color="danger" > 
                    <FontAwesomeIcon icon={faTrashCan} />
                </IconButton>
            </Box>
            <Grid xs={12} >
                <Typography level="h2" fontSize="lg" >
                    {product.name}
                </Typography>
            </Grid>
            <Grid xs={12} >
                <Typography level="h3" fontSize="md" >
                    {product.category}
                </Typography>
            </Grid>
            <Grid xs={12} >
                <Typography level="body2"> Descrição: {product.description} </Typography>
            </Grid>
            <Grid xs={12} >
                <Grid container justifyContent="space-between" >
                    <Grid xs={6} >
                        <Typography level="body3">Preço:</Typography>
                        <Typography fontSize="md" fontWeight="lg">
                            R$ {toDecimal(product.price)}
                        </Typography>
                    </Grid>
                    <Grid xs={6} >
                        <Typography level="body3">Medida:</Typography>
                        <Typography fontSize="md" fontWeight="lg">
                            {product.measure}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
           
        </Grid>
        <Modal open={deleteModalOpen}  onClose={()=> setDeleteModalOpen(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <ModalDialog layout="center" >
                <ModalClose variant="outlined" />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Remover produto
                    </Typography>
                    <Typography id="modal-desc" textColor="text.tertiary" mb={6} >
                       Certeza que quer remover o produto "{product.name}"?
                    </Typography>
                    <Grid container justifyContent="center" >
                        <Button onClick={handleDelete} loading={isDeleteloading} color="danger" >remover</Button>
                    </Grid>
            </ModalDialog>
        </Modal>
    </Card>
}