
import { faArrowUpRightFromSquare, faBasketShopping, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Chip, Grid, IconButton, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { toDecimal } from "../../helpers/number";
import { measureUnits } from "../../helpers/measure";
import PriceLabel from "../PriceLabel";
import FavoriteButton from "../FavoriteButton";
import QuantityInput from "../QuantityInput";
import { cart } from "../../store";
import { observer } from "mobx-react-lite";


const ProductCard = observer(({product, onRemove, short = false })=>{
    const navigate = useNavigate()

    const productOnCart = cart.getProduct(product._id)

    const handleNewQuantityOnCart = (newQuantity) => { 
        if(newQuantity === 0) cart.removeProduct(product._id)
        else cart.updateQuantity(product, newQuantity)
    }

    return <Card variant="outlined" sx={{ width: '100%' }}  >
        <Grid container alignItems="center" >
            <Box sx={{ position: 'absolute', top: '0.3rem', right: '0.3rem' }} >
                <FavoriteButton type="products" object={product} />
            </Box>
            <Grid xs={12} >
                <Typography level="h2" fontSize="lg" >
                    {product.name}
                </Typography>
            </Grid>
            {
                product.producer?._id &&  <Grid xs={7} padding={0.5} >
                    <Typography level="h3" fontSize="md" >
                        Por <Chip onClick={()=> navigate(`/catalogo/${product.producer._id}/produtor`)} size="sm" variant="soft" endDecorator={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}  > {product.producer.name} </Chip>
                    </Typography>
                </Grid>
            }
           
            <Grid xs={5} > 
                <Typography level="h3" fontSize="sm" >
                    Cat. {product.category}
                </Typography>
            </Grid>
            { 
                short && <Grid xs={12} padding={0.2} >
                    <Typography level="body2"> Descrição: {product.description} </Typography>
                </Grid>
            }
            <Grid xs={12} mt={1} >
                <Grid container justifyContent="space-between" >
                    <Grid xs={6} >
                        <Typography level="body3">Preço:</Typography>
                        <Typography fontSize="md" fontWeight="lg">
                            <PriceLabel price={toDecimal(product.price)} /> 
                        </Typography>
                    </Grid>
                    <Grid xs={6} >
                        <Typography level="body3">Medida:</Typography>
                        <Typography fontSize="md" fontWeight="lg">
                            {measureUnits[product.measure]}({product.measure})
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} mt={1} >
                { 
                    productOnCart?.quantity
                    ? <Grid xs={12} > 
                        <QuantityInput 
                            quantityUpdater={handleNewQuantityOnCart} 
                            quantity={productOnCart.quantity} 
                        />
                    </Grid>
                    : <Grid container justifyContent="center" >
                        <Grid xs={4} >
                            <Button 
                                size="sm" 
                                variant="soft" 
                                startDecorator={<FontAwesomeIcon icon={faBasketShopping} />}
                                onClick={()=> handleNewQuantityOnCart(1)}
                            > 
                                Adicionar
                            </Button> 
                        </Grid>
                    </Grid>
                } 
            </Grid>
        </Grid>
    </Card>
})

export default ProductCard 