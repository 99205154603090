import { makeAutoObservable } from "mobx";
import moment from "moment";
import privateClient from "../clients/privateClient";

const PRODUCER_KEY = 'producer'
const PRODUCER_REFRESH_DATE_KEY = 'producer-refresh-date-key'

class ProducerStore {
    initialized = false
    producer = JSON.parse(localStorage.getItem(PRODUCER_KEY))
    refreshDate = localStorage.getItem(PRODUCER_REFRESH_DATE_KEY)
    isProducer = !!localStorage.getItem(PRODUCER_KEY)

    constructor(){
        makeAutoObservable(this)
    }

    async init(){
        if(this.initialized) return;
        this.initialized = true

        const refreshDateMoment = moment(this.refreshDate)

        let expired = !(this.refreshDate && moment().diff(refreshDateMoment,'days') <= 7)

        if(expired){
           this.refresh()
        }

    }

    setProducer(producer){
        localStorage.setItem(PRODUCER_KEY,  producer ? JSON.stringify(producer) : null )

        this.producer = producer || null;
        this.isProducer = true 
    }

    setRefreshDate(){ 
        const refreshDate = moment().format('YYYY-MM-DD')

        localStorage.setItem(PRODUCER_REFRESH_DATE_KEY, refreshDate)

        this.refreshDate = refreshDate
    }

    clearProducer(){ 
        localStorage.removeItem(PRODUCER_KEY)

        this.producer = null
        this.isProducer = false
    }

    refresh(){
        privateClient().get('/producer')
            .then(res =>{
                if(res.data) this.setProducer(res.data)

                this.setRefreshDate()
            })
            .catch(this.clearProducer)
    }

    clear(){
        localStorage.removeItem(PRODUCER_KEY)
        localStorage.removeItem(PRODUCER_REFRESH_DATE_KEY)

        this.producer = null
        this.isProducer = false
        this.refreshDate = null
    }
}

export default ProducerStore