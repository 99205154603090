import { faBasketShopping, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Grid } from "@mui/joy";
import { useNavigate } from "react-router-dom";


export default function BottomBar(){
    const navigate = useNavigate()

    return <Box sx={{ position: 'fixed', left: 0, bottom: -3, maxHeight: '4.5rem', width: '100%', display: { md: 'none' } }} >
        <Card variant="outlined" style={{ padding: 2, }}  >
            <Grid container justifyContent="center" >
                <Grid xs={6} md={6} >
                    <Button onClick={()=> navigate('/carrinho')} fullWidth startDecorator={<FontAwesomeIcon icon={faBasketShopping} />} > Cesta </Button>
                </Grid>
                <Grid xs={6} md={6} >
                    <Button onClick={()=> navigate('/favoritos')} fullWidth color="danger" startDecorator={<FontAwesomeIcon icon={faHeart} />} > Favoritos </Button>
                </Grid>
            </Grid>
        </Card>
    </Box>
}