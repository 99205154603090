import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Card, Grid, Typography, FormLabel, FormControl, Button, Input, Alert } from '@mui/joy';
import FormValidator from '../services/formValidator.service';
import client from '../clients/privateClient'
import { CustomInput } from '../components/standard/CustomInput';
import * as CPF from 'gerador-validador-cpf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { producerStore } from '../store';

const formValidator = new FormValidator() 

const RuralProducerRegisterPage = observer(() => {
    const navigate = useNavigate()

    const [saveLoading, setSaveLoading] = useState(false)
     
    const formState = useState({
        name: { 
            value: '',
            rules: {
                isFilled: true,
            },
        },
        cpf: { 
            value: '',
            mask: '999.999.999-99',
            rules: {
                isFilled: true,
            },
        },
        celular: { 
            value: '',
            mask: '(99)99999-9999',
            rules: {
                isFilled: true,
            },
        },
        email: { 
            value: '',
            rules: {
            },
        },
        ruralRegistration: { 
            value: '',
            mask: '999999999.99-99',
            rules: {
            },
        },
        
    })

    const [errorMessage,setErrorMessage] = useState(null)

    
    const save = () => {
        if(!isValidCpf) return setErrorMessage({ msg: 'CPF incorreto!'})

        setSaveLoading(true)

        if(!formValidator.validate()){ 
            client().post('/producer', formValidator.sendFormat())
                .then( (res) => {
                    producerStore.refresh()
                    navigate('/')
                })
                .catch((err) =>{
                    setErrorMessage(err.response.data);
                })
                .finally(()=> {
                    setSaveLoading(false)
                })
        } else 
            setSaveLoading(false)
    }

    useEffect(()=>{
        formValidator.setFormState(formState)
    })

    const isValidCpf = CPF.validate(formState[0].cpf.value)

  return (
    <Grid container spacing={2} justifyContent="center" >
       
        <Grid  xs={12} md={6} lg={4} >
            { errorMessage && <Alert sx={{ mb: 1 }} color="danger" size="md" variant="soft"> {errorMessage.msg} </Alert> } 
            <Card>
                <Grid  sx={{flexGrow: 1}} >
                    <Grid>
                        <Typography level="h4" >Cadastro do Produtor</Typography>
                    </Grid>
                    <Grid>
                        <FormControl required>
                            <FormLabel>Nome da barraca/loja</FormLabel>
                            <CustomInput placeholder="Barraca do Zé ou Fazenda Oliveira..."  name="name" formState={formState} />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl required>
                            <FormLabel>CPF</FormLabel>
                            <CustomInput  
                                placeholder="999.999.999-99" 
                                name="cpf" 
                                formState={formState} 
                                endDecorator={
                                    isValidCpf
                                    ? <FontAwesomeIcon color="green"  icon={faCheckCircle} /> 
                                    : <FontAwesomeIcon color="red" icon={faWarning} />
                                } 
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl required>
                            <FormLabel>Celular profissional</FormLabel>
                            <CustomInput  placeholder="(99)99999-9999" name="celular" formState={formState} />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl>
                            <FormLabel>Email profissional</FormLabel>
                            <CustomInput  placeholder="exemplo@gmail.com" name="email" formState={formState} />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl>
                            <FormLabel>Cadastro do Produtor Rural</FormLabel>
                            <CustomInput  placeholder="" name="ruralRegistration" formState={formState} />
                        </FormControl>
                    </Grid>
                    <Grid container padding={2} justifyContent="center" >
                        <Button onClick={save} loading={saveLoading} >
                            Cadastrar
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        
    </Grid> 
  );
})

export default RuralProducerRegisterPage 