import { faChevronDown, faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Card, FormControl, FormLabel, Grid, IconButton, Input, Typography } from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import FormValidator from "../services/formValidator.service";
import { searchAddressByCEP } from "../helpers/address";
import AlertFormErrors from "./AlertFormErrors";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { CustomInput } from "./standard/CustomInput";
import { unmask } from "../helpers" 

const getPristineForm = (address) =>({ 
    cep: { 
        value: address?.cep || '',
        mask: '99999-999',
        rules: {
            isFilled: true,
            minChar: 9,
        },
    },
    logradouro: { 
        value: address?.logradouro || '',
        rules: {
            isFilled: true,
            minChar: 6,
        },
    },
    numero: { 
        value: address?.numero || '',
        rules: {
            isFilled: true,
            minChar: 1,
        },
    },
    bairro: { 
        value: address?.bairro || '',
        rules: {
            isFilled: true,
            minChar: 6,
        },
    },
    cidade: { 
        value: address?.cidade || '',
        rules: {
            isFilled: true,
            minChar: 6,
        },
    },
    estado: { 
        value: address?.estado || '',
        rules: {
            isFilled: true,
            minChar: 1,
        },
    },
})

const formValidator = new FormValidator()

export default function PickAddress({ from=null, startOpened=false, onSave, onComplete=null, currentAddress=null, withSaveButton=true, withToggleButton=true  }){
    const formState = useState(getPristineForm())
    const [form, setForm] = formState

    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isEditing, setIsEditing] = useState(!currentAddress)
    const [errors, setErrors] = useState(null)

    const [isOpen, setIsOpen] = useState(startOpened)

    const toggleOpen = () => withToggleButton && setIsOpen(!isOpen)

    const save = async () =>{
        setLoading(true)

        const validationResult = formValidator.validate()

        if(validationResult){
            setErrors(validationResult)
            setLoading(false)
            return;
        } 
        try{
            await onSave(formValidator.sendFormat())

            setIsSuccess(true)
        }finally {
            setLoading(false)
            setIsEditing(false)
        }
    }

    const jumpToEditingMode = () => {
        setForm(getPristineForm(currentAddress))

        setIsEditing(true)
    }

    const autoFillFields = (address)=>{
        const formCopy = Object.assign({},form)

        if(!formCopy.estado.value) formCopy.estado.value = address.uf
        if(!formCopy.cidade.value) formCopy.cidade.value = address.localidade
        if(!formCopy.bairro.value) formCopy.bairro.value = address.bairro
        if(!formCopy.logradouro.value) formCopy.logradouro.value = address.logradouro
        
        setForm(formCopy)
    }
    
    const isValidAddress = !!formValidator.form && !formValidator.validate()

    useEffect(()=>{
        if(unmask(form.cep.value).length === 8){
            searchAddressByCEP(form.cep.value.replace('-','')).then(res =>{
                autoFillFields(res.data)
            })
        }

    },[form.cep.value])

    useEffect(()=>{
        if(onComplete && isValidAddress) onComplete(formValidator.sendFormat())
    },[form])

    useEffect(()=>{
        formValidator.setFormState(formState)
    },[])

    const memoAlertErrors = useMemo( 
        ()=> errors &&  <Grid xs={12} mb={1.5} > <AlertFormErrors errors={errors} /> </Grid>
    ,[errors])

    return <Card variant="outlined" >
        <Grid  container justifyContent="space-between" onClick={toggleOpen} >
            <Typography fontSize="lg" > { isValidAddress ? <FontAwesomeIcon color="green" icon={faCheckCircle} /> : null } {from} </Typography>
            {
                withToggleButton 
                ? <IconButton size="sm" variant="plain" > <FontAwesomeIcon icon={ isOpen ? faChevronUp : faChevronDown} /> </IconButton>
                : null
            }
        </Grid>

        { isSuccess 
            ? <Grid>
                <Alert variant="soft" color="success" endDecorator={<IconButton color="success" variant="plain" onClick={()=>setIsSuccess(false)} > <FontAwesomeIcon icon={faClose} /> </IconButton>} > 
                    Endereço salvo com sucesso! 
                </Alert>
            </Grid>
            : <></> 
        }
        {
            isOpen && isEditing 
            ? <Grid container justifyContent="center" mt={1.5} spacing={0.3} >
                {memoAlertErrors}
                <Grid xs={12} >
                    <FormControl required>
                        <FormLabel>CEP</FormLabel>
                        <CustomInput placeholder="XXXXX-XXX" size="sm" name="cep" formState={formState} />
                    </FormControl>
                </Grid>
                <Grid xs={9} >
                    <FormControl required>
                        <FormLabel>Logradouro</FormLabel>
                        <CustomInput placeholder="Avenida Brasil" size="sm" name="logradouro" formState={formState} />
                    </FormControl>
                </Grid>
                <Grid xs={3} >
                    <FormControl required>
                        <FormLabel>Numero</FormLabel>
                        <CustomInput placeholder="123" size="sm" name="numero" formState={formState} />
                    </FormControl>
                </Grid>
                <Grid xs={12} >
                    <FormControl required>
                        <FormLabel>Bairro</FormLabel>
                        <CustomInput placeholder="Bairro" size="sm" name="bairro" formState={formState} />
                    </FormControl>
                </Grid>
                <Grid xs={8} >
                    <FormControl required>
                        <FormLabel>Cidade</FormLabel>
                        <CustomInput placeholder="Cidade" size="sm" name="cidade" formState={formState} />
                    </FormControl>
                </Grid>
                <Grid xs={4} >
                    <FormControl required>
                        <FormLabel>Estado</FormLabel>
                        <CustomInput placeholder="Estado" size="sm" fullWidth name="estado" formState={formState} />
                    </FormControl>
                </Grid>
                <Grid xs={12} mt={3} justifyContent="space-evenly" container >
                    { 
                        currentAddress && <Button color="danger" size="sm" variant="plain" onClick={()=> setIsEditing(false)} >Cancelar</Button>
                    }
                    {
                        withSaveButton ? <Button color="primary" size="sm" onClick={save} loading={loading} >Salvar</Button> : null
                    }
                </Grid>
            </Grid>
            : <></>
        }
        {
            isOpen && !isEditing && currentAddress
            ? <Grid container justifyContent="space-between" mt={2.5} spacing={0.3} >
                <Typography  > {currentAddress.logradouro} {currentAddress.numero}, {currentAddress.cidade}-{currentAddress.estado} </Typography>
                <Button size="sm"  onClick={jumpToEditingMode}  >Editar</Button>
            </Grid>
            : <></>
        }
        
    </Card> 
}