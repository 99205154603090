import { Button, Card, Grid, Table, Typography } from "@mui/joy";
import { toDecimal } from "../../helpers/number";
import { useState } from "react";
import PriceLabel from "../PriceLabel";
import DateLabel from "../DateLabel";

export default function OrderCard({ order }){
    const [showItems, setShowItems] = useState(false)

    const toggleShowItems = () => setShowItems(!showItems)

    return <Card variant="outlined" sx={{ width: '100%' }} >
        
        <Grid container >
            <Grid xs={12} >
                <Typography level="h5" > {order.producer.name} </Typography>
            </Grid>
            <Grid xs={6} >
                <PriceLabel price={toDecimal(order.total)} />
            </Grid>
            <Grid xs={6} >
                <DateLabel date={order.createdAt} />
            </Grid>
            {
                showItems
                ? <Grid xs={12} > 
                    <Table >
                        <thead>
                            <tr>
                                <th>Qtd.</th>
                                <th>Produto</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                order.products.map( item =>{
                                    return <tr key={item._id} >
                                        <td>{item.quantity}</td>
                                        <td> {item.name} </td>
                                        <td>{toDecimal(item.price * item.quantity)}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                    <Button onClick={toggleShowItems} variant="plain" fullWidth size="sm" > Fechar </Button>
                </Grid>
                : <Grid xs={12} > <Button onClick={toggleShowItems} variant="plain" fullWidth size="sm" > Mostrar Items </Button> </Grid>
            }
        </Grid>
    </Card>
}