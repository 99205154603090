import { makeAutoObservable } from "mobx";
import { auth } from ".";
import { deleteFavorites, postFavorites } from "../requests/user";


class FavoriteStore {
    favorites = null 

    constructor(){
        makeAutoObservable(this)
    }

    init(favorites){
        if(!auth.isAuthenticated) return false

        this.favorites = favorites
    }


    async toggle(type, object){
        if(this.isFavorited(type, object._id)){
            const {data} = await deleteFavorites(object._id, type)

            this.favorites = data
        }else{
            const indexS = type.lastIndexOf('s')
            const singularType = indexS !== -1 ? type.substring(0, indexS) : type

            const {data} = await postFavorites({ [singularType]: object })

            this.favorites = data 
        }
    }

    isFavorited(type, id){
        return this.favorites && !!this.favorites[type]?.find( element => element._id === id)
    }

}

export default FavoriteStore