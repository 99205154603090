import React, { useState, useEffect } from 'react'
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import customTheme from '../../theme';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../store';
import ColorSchemeToggle from '../../components/layout/ColorSchemeToggle';
import { GoogleLogin } from '@react-oauth/google';
import { isMobile } from '../../helpers/media';
import client from '../../clients/client';
import InputMask from 'react-input-mask';
import { unmask } from '../../helpers';
import { Alert } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';


export default function LoginPage() {
  const navigate = useNavigate()

  const [celular, setCelular] = useState('')
  const [password, setPassword] = useState('')
  const [loginErrorAlert, serLoginErrorAlert] = useState(null)

  const [loginButtonLoading, setLoginButtonLoading] = useState(false)

  const login = async () => {
    const celularWithoutFormat = unmask(celular)

    if(celularWithoutFormat.length < 11 ) return serLoginErrorAlert('Celular ou Senha incorretos!');
    if(password.length < 8 ) return serLoginErrorAlert('Celular ou Senha incorretos!');

    setLoginButtonLoading(true)
    
    try{
       const { data } = await client.post('/auth/app/login',{ password, celular: celularWithoutFormat })

       auth.authenticate(data.token, data.user)

       navigate('/')
    }catch(e){
        // errorToast('Falha no login');
    }
    
    setLoginButtonLoading(false)
}


const onGoogleSuccess = (google) => {
  client.get('/auth/google',{ headers: { 'Authorization': `Bearer ${google.credential}`}})
      .then( res =>{
          auth.authenticate(res.data.token, res.data.user);
          navigate('/')
      })
      .catch( err =>{

      })
}

  useEffect(()=>{
    if(auth.isAuthenticated) navigate('/')
  },[])

  return (
    <CssVarsProvider
      defaultMode="dark"
      disableTransitionOnChange
      theme={customTheme}
    >
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '40vw', // must be `vw` only
            '--Form-maxWidth': '700px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(255 255 255 / 0.6)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              fontWeight="lg"
            >
              Connect Roça
            </Typography>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <div>
                <Typography component="h2" fontSize="xl2" fontWeight="lg">
                    Bem vindo, de volta!
                </Typography>
            </div>
              <FormControl required>
                { loginErrorAlert &&  <Box mb={2} > <Alert  startDecorator={ <FontAwesomeIcon icon={faTriangleExclamation} /> }  variant="outlined" color="danger" > {loginErrorAlert}  </Alert> </Box>}
                <FormLabel>Celular</FormLabel>
                <InputMask
                   mask="(99)99999-9999"
                   value={celular}
                   disabled={false}
                   maskChar=" "
                   onChange={(event) => setCelular(event.target.value)}
                >
                  {() =>  <Input placeholder="Celular" /> }
                </InputMask>
                
              </FormControl>
              <FormControl required>
                <FormLabel>Senha</FormLabel>
                <Input value={password} onChange={(event)=> setPassword(event.target.value)} placeholder="•••••••" type="password" />
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <Link fontSize="sm" href="#replace-with-a-link" fontWeight="lg">
                    Esqueci minha senha
                </Link>
                </Box>
                
                <Button onClick={login} loading={loginButtonLoading} fullWidth>
                  Entrar
                </Button>
                
                <GoogleLogin auto_select size="large" width={isMobile() ? '360px' : '400px' } onSuccess={onGoogleSuccess} />
                <Button onClick={() => navigate('/cadastrar')} fullWidth>
                    Cadastrar
                </Button>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body3" textAlign="center">
              © Connect Roça {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831)',
          },
        })}
      />
    </CssVarsProvider>
  );
}
