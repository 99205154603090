import { faChevronRight, faPlus, faTableList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Grid, IconButton, List, ListItem, Typography } from "@mui/joy";
import ColorizedDot from "../../components/standard/ColorizedDot";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Countertops } from "@mui/icons-material";

const ProducerHomePage = () => {
    const navigate =  useNavigate();

    const [orders, setOrders] = useState([])
    const [countStatus, setCountStatus] = useState({})

    const {response} = useLoaderData()

    response.then( res =>{ 
        setOrders(res.data.orders)
        setCountStatus(res.data.countStatus)
    })


    return <Grid container spacing={2} >
        <Grid xs={12} md={6} lg={4} >
            <Card variant="outlined" >
                <Grid flexWrap="wrap" container >
                    <Grid xs={11} >
                        <Typography level="h5" > Pedidos </Typography>
                    </Grid>
                    <Grid xs={1} >
                        <IconButton variant="plain" size="sm" > <FontAwesomeIcon icon={faChevronRight} /> </IconButton>
                    </Grid>
                </Grid>
                <List>
                    <ListItem endAction={ <Typography fontWeight="lg" > {countStatus.created} </Typography> } > 
                        <ColorizedDot color="primary" /> Pedidos em aberto:  
                    </ListItem>
                    <ListItem endAction={ <Typography fontWeight="lg" > {countStatus.canceled} </Typography> } > 
                        <ColorizedDot color="danger" /> Pedidos cancelados:  
                    </ListItem>
                    <ListItem endAction={ <Typography fontWeight="lg" > {countStatus.finished} </Typography> } > 
                        <ColorizedDot color="success" /> Pedidos já realizados:  
                    </ListItem>
                </List>
            </Card>
        </Grid>
        <Grid xs={12} md={6} lg={4} >
            <Card variant="outlined" >
                <Grid flexWrap="wrap" container >
                    <Grid xs={11} >
                        <Typography level="h5" > Catálogo </Typography>
                    </Grid>
                    <Grid xs={1} >
                        <IconButton onClick={()=> navigate('/produtor/gerenciar/catalogo')} variant="plain" size="sm" > <FontAwesomeIcon icon={faChevronRight} /> </IconButton>
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid xs={6} >
                        <Button onClick={()=> navigate('/produtor/produto/cadastrar')} size="sm" color="success" variant="solid" fullWidth startDecorator={<FontAwesomeIcon icon={faPlus} />}  >  
                            Adicionar Produto 
                        </Button>
                    </Grid>
                    <Grid xs={6} >
                        <Button onClick={()=> navigate('/produtor/gerenciar/catalogo')} size="sm" variant="solid" fullWidth startDecorator={<FontAwesomeIcon icon={faTableList} />}  >  
                            Abrir catálago
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        <Grid xs={12} md={6} lg={4} >
        </Grid>
    </Grid>
}

export default ProducerHomePage;