import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Chip, Grid, IconButton, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { faChevronRight, faHeart } from "@fortawesome/free-solid-svg-icons";
import { postFavorites } from "../../requests/user";
import FavoriteButton from "../FavoriteButton";


export default function ProducerCard({ producer }){

    const navigate = useNavigate()

    const handleFavorite = ()=>{
        postFavorites({ producer })
    }

    return <Card variant="outlined" sx={{ width: '100%' }}  >
        <Grid container alignItems="center" >
            <Box sx={{ position: 'absolute', top: '0.3rem', right: '0.3rem' }} >
                <FavoriteButton type="producers" object={producer} />
                <IconButton sx={{ marginLeft: 1 }} color="primary" onClick={ ()=> navigate(`/catalogo/${producer._id}/produtor`,{ state: { producer } }) } variant="soft" size="sm" > <FontAwesomeIcon icon={faChevronRight} /> </IconButton>
            </Box>
            <Grid xs={12} >
                <Typography level="h2" fontSize="lg" >
                    {producer.name}
                </Typography>
            </Grid>
            <Grid xs={7} ml={0.5} mt={0.1} mb={0.4} >
                <Typography level="h3" fontSize="md" >
                    De Teofilo Otoni
                </Typography>
            </Grid>
            <Grid xs={12} >
                <Typography level="h3" fontSize="sm" >
                    Especialidade:
                </Typography>
                {
                    producer.expertise.map( category => <Chip key={category} sx={{ margin: 0.1 }} size="sm" variant="outlined" > {category} </Chip> )
                }
            </Grid>
        </Grid>
    </Card>
}