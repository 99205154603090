import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Input } from "@mui/joy";


export default function QuantityInput({ quantityUpdater, quantity }){
    const addCount = () => quantityUpdater(parseInt(quantity+1))
    const subCount = () => quantity < 1  ? null : quantityUpdater(parseInt(quantity-1))
    const handleCount = (event) => {
        if(event.target.value === '') quantityUpdater(event.target.value)

        const number = parseInt(event.target.value)
        if (!isNaN(number)) quantityUpdater(number)
    }

    return <Grid container justifyContent="center" >
        <Grid  > 
            <IconButton onClick={addCount} size="sm" variant="soft" color="primary" > 
                <FontAwesomeIcon icon={faPlus} />
            </IconButton> 
        </Grid>
        <Grid xs={2} md={2} > 
            <Input onChange={handleCount} size="sm" fullWidth value={quantity} /> 
        </Grid>
        <Grid  > 
            <IconButton onClick={subCount} size="sm" variant="soft" color="primary" > 
                <FontAwesomeIcon icon={faMinus} /> 
            </IconButton>  
        </Grid>
    </Grid>
}


