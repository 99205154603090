import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import SecondSidebar from "./components/layout/Drawer";
import Header from "./components/layout/Header";

import customTheme from "./theme";
import { Outlet } from "react-router-dom";
import { auth, cart, favorite, producerStore } from "./store";
import { getProfile } from "./requests/user";
import { CartLocalStorage, CartRemoteStorage } from "./services/cartStorage.service";

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export default function App() {
  const status = null;

  const [isInitializedStore, setIsInitializedStore] = React.useState(false)

  useEnhancedEffect(() => {

    if (typeof feather !== "undefined") {
      feather.replace();
    }
  }, [status]);

  React.useEffect(()=>{
    if(!isInitializedStore){ 
      setIsInitializedStore(true)

      if(auth.isAuthenticated){ 
        getProfile().then( res =>{
          cart.init(res.data.cart, new CartRemoteStorage())
          favorite.init(res.data.favorites)
        })
        producerStore.init()

      }else{
        cart.init(CartLocalStorage.get(), new CartLocalStorage())
      }
      
    }
  },[])

  return (
      <CssVarsProvider disableTransitionOnChange theme={customTheme}>
        <GlobalStyles
          styles={{
            "[data-feather], .feather": {
              color: "var(--Icon-color)",
              margin: "var(--Icon-margin)",
              fontSize: "var(--Icon-fontSize, 20px)",
              width: "1em",
              height: "1em"
            }
          }}
        />
        <CssBaseline />
        <Box sx={{ display: "flex", minHeight: "100dvh" }}>
          <Header />
          <SecondSidebar />
          <Box
            component="main"
            className="MainContent"
            sx={(theme) => ({
              px: {
                xs: 2,
                md: 6
              },
              pt: {
                xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
                sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
                md: 3
              },
              pb: {
                xs: 2,
                sm: 2,
                md: 3
              },
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              height: "100dvh",
              gap: 1, 
              overflowY: "scroll",
            })}
          >
            <Outlet />
          </Box>
        </Box>
      </CssVarsProvider>
  );
}
