import { makeAutoObservable } from "mobx";
import { cart, producerStore } from ".";
import { CartRemoteStorage } from "../services/cartStorage.service";

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

class AuthStore {
    token = localStorage.getItem(TOKEN_KEY)
    user = JSON.parse(localStorage.getItem(USER_KEY))
    isAuthenticated = !!localStorage.getItem(TOKEN_KEY)

    constructor(){
        makeAutoObservable(this)
    }

    authenticate( token, user ){
        localStorage.setItem(TOKEN_KEY, token)
        localStorage.setItem(USER_KEY,  JSON.stringify(user) )
        
        this.token = token
        this.user = user
        this.isAuthenticated = true

        cart.changeStorage(this.isAuthenticated)

        producerStore.refresh()
    }

    unauthenticate(){
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(USER_KEY)

        this.token = null
        this.user = null
        this.isAuthenticated = false

        if(producerStore.isProducer) producerStore.clear()
    }

    updateUser(user){
        localStorage.setItem(USER_KEY,  JSON.stringify(user) )
        
        this.user = user 
    }

    updateCart(cart){
        this.user.cart = cart

        localStorage.setItem(USER_KEY, JSON.stringify(Object.assign({}, this.user)) )
    }


    removeItemToCart(cartItemId){
        const index = this.user.cart.findIndex( cartItem => cartItem._id === cartItemId )

        this.user.cart.splice(index,1)

        localStorage.setItem(USER_KEY, JSON.stringify(Object.assign({}, this.user)) )
    }

    static getToken(){ 
        return localStorage.getItem(TOKEN_KEY)
    }
}

export default AuthStore;