import client from "../clients/client";
import privateClient from "../clients/privateClient";

export const getProducts = (producerId) => privateClient().get(`/producer/${producerId}/products`)

export const deleteProduct = (producerId, productId) => privateClient().delete(`/producer/${producerId}/product/${productId}`)

export const getProducerById = (producerId) => client.get(`/producer/${producerId}/id`)

export const putProducer =  (producerId,data) =>  privateClient().put(`/producer/${producerId}/id`, data)

export const putProducerAddress = (producerId, data) => privateClient().put(`/producer/${producerId}/address`, data)

export const postProducerContact =  (producerId, data) => privateClient().post(`/producer/${producerId}/contact`,data)

export const deleteProducerContact = (producerId, contactKey ) => privateClient().delete(`/producer/${producerId}/contact/${contactKey}`)

export const postProduct = (producerId, data) => privateClient().post(`/producer/${producerId}/product`, data )

export const putProduct = (producerId, productId, data) => privateClient().put(`/producer/${producerId}/product/${productId}`, data )