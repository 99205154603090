import { Alert, Button, Card, FormControl, FormLabel, Grid, Input, Option, Select, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import FormValidator from "../../../../services/formValidator.service";
import { producerStore } from "../../../../store";
import privateClient from "../../../../clients/privateClient";
import { useLocation } from "react-router-dom";
import { toDecimal } from "../../../../helpers/number";
import { measureUnits } from "../../../../helpers/measure";
import { selectCategoryOptions } from "../../../../components/selectCategoryOptions";
import { CurrencyInput, CustomInput } from "../../../../components/standard/CustomInput";
import { postProduct, putProduct } from "../../../../requests/producer";

const getPristineForm = (product) =>({ 
    name: { 
        value: product?.name || '',
        rules: {
            isFilled: true,
        },
    },
    description: { 
        value: product?.description || '',
        rules: {
            isFilled: true,
        },
    },
    price: { 
        value: (product?.price && toDecimal(product?.price).replace('.',',') ) || '',
        mask: '###0,00',
        reverseMask: true,
        numeric: true,
        rules: {
            isFilled: true,
        },
        converter: 'toCents'
    },
    measure: { 
        value: product?.measure || '',
        rules: {
            isFilled: true,
        },
    },
    category: { 
        value: product?.category || '',
        rules: {
            isFilled: true,
        },
    },
})

const formValidator = new FormValidator()

export default function AddProductPage(){
    const location = useLocation()

    const [message, setMessage] = useState(null)
    const [saveLoading, setSaveLoading] = useState(false)
    const formState = useState(
        getPristineForm( 
            location?.state?.editMode && location?.state?.product ? location.state.product : {}
        )
    )
    const [form, setForm] = formState

    const save = () => { 
        setSaveLoading(true)

        if(!formValidator.validate()){ 
            if(location?.state?.editMode){
                putProduct(producerStore.producer._id, location?.state?.product?._id, formValidator.sendFormat())
                .then((res) => {
                    setMessage({
                        msg: `${form.name.value} editado com sucesso!`, 
                        color: 'success'
                    })
                })
                .finally(()=> setSaveLoading(false))
            }else{ 
                postProduct(producerStore.producer._id, formValidator.sendFormat())
                .then((res) => {
                    setMessage({
                        msg: `${form.name.value} salvo com sucesso!`, 
                        color: 'success'
                    })

                    setForm(getPristineForm())
                })
                .catch((err)=>{
                    
                })
                .finally(()=> setSaveLoading(false))
            }
        }else{ 

        }
    }

    const handleChange = (name, value) => {
        const formCopy = Object.assign({}, formState[0] )

        formCopy[name].value = value

        formState[1](formCopy)
    }

    useEffect(()=>{
        formValidator.setFormState(formState)
    },[])

    return  <Grid container spacing={2} justifyContent="center" >
       
        <Grid  xs={12} md={6} lg={4} >
            { message && <Alert sx={{ mb: 1 }} color={message.color} size="md" variant="soft"> {message.msg} </Alert> } 
            <Card>
                <Grid  sx={{flexGrow: 1}} >
                    <Grid>
                        <Typography level="h4" >Cadastrar Produto</Typography>
                    </Grid>
                    <Grid>
                        <FormControl required>
                            <FormLabel>Nome</FormLabel>
                            <CustomInput placeholder="Queijo mussarela" name="name" formState={formState} />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl required>
                            <FormLabel>Descrição</FormLabel>
                            <CustomInput placeholder="Queijo de massa filada com origem na comuna de Aversa" name="description" formState={formState}  />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl required>
                            <FormLabel>Preço</FormLabel>
                            <CurrencyInput placeholder="$50,00" name="price" formstate={formState} />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl required>
                            <FormLabel>Medida</FormLabel>
                            <Select placeholder="Selecione uma medida" name="measure" onChange={(event, value)=> handleChange('measure', value) } >
                                {
                                    Object.keys(measureUnits).map( 
                                        key => <Option key={key} value={key} >
                                            {measureUnits[key]}({key})
                                        </Option>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl required>
                            <FormLabel>Categoria</FormLabel>
                            <Select placeholder="Selecione uma categoria" name="category" onChange={(event, value) =>   handleChange('category', value) } >
                                {selectCategoryOptions()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container padding={2} justifyContent="center" >
                        <Button onClick={save} loading={saveLoading} >
                            { location?.state?.editMode ? 'Editar' : 'Cadastrar' }  
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        
    </Grid> 
}