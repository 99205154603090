import { makeAutoObservable } from "mobx";
import { deleteCart, postCart } from "../requests/cart";
import { CartRemoteStorage } from "../services/cartStorage.service";

class CartStore {
    cart = []
    storage = null

    constructor(){
        makeAutoObservable(this)
    }

    async init(cart, storage){
        this.cart = cart
        this.storage = storage
    }

    getByProducer(){
        const byProducer = {}

        this.cart.forEach( item =>{
            const key = item.product.producer?._id || item.product.producer
            
            if(!byProducer[key]) byProducer[key] = { cart: [], subtotal: 0 }

            byProducer[key].cart.push(item)
            byProducer[key].subtotal += item.total 

        })

        return byProducer
    }

    clearByProducer(producerId){ 
        this.cart = this.cart.filter( item => producerId !== (item.product.producer?._id || item.product.producer) )
    }

    getProduct(productId){ 
        return this.cart.find( item => item?.product?._id === productId )
    }

    getIndexOfProduct(productId){  
        return this.cart.findIndex( item => item?.product?._id === productId )
    }

    removeProduct(productId){
        this.cart.splice( this.getIndexOfProduct(productId), 1)
        
        return this.storage.delete(productId)
    }

    updateQuantity(product, quantity){
        let productOnCart = this.getProduct(product._id)

        if(!productOnCart) { 
            productOnCart = { product, quantity, total: product.price * quantity }; 
            this.cart.push(productOnCart)
        }
        else productOnCart.quantity = quantity

        return this.storage.update(product, quantity)

    }

    changeStorage(isAuthenticated){ 
        if(isAuthenticated){
            const newStorage = new CartRemoteStorage()

            this.storage.transferTo(newStorage)

            this.cart.storage = newStorage
        }else{ 
            this.storage = new CartRemoteStorage()
            this.cart = []
        }
    }

}

export  default CartStore