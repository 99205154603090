import { faArrowRightFromBracket, faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, Typography } from "@mui/joy"
import { auth, producerStore } from "../../store"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"


const LoggedUserContainer = observer(() => {
    const navigate = useNavigate()

    const logout = () => { 
        auth.unauthenticate();
        navigate('/')
    }

    if(auth.isAuthenticated){

        return <>
            <div>
                <Typography fontWeight="lg" level="body2">
                    {auth.user.name}
                </Typography>
                <Typography level="body2"> {auth.user.contacts.celular} </Typography>
            </div>
            <IconButton onClick={logout} variant="plain" sx={{ ml: 'auto' }}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </IconButton>
        </>
    }

    return  <>
        <div>
            <Typography fontWeight="lg" level="body2">
                Entrar
            </Typography>
        </div>
        <IconButton onClick={()=> navigate('/login')} variant="plain" sx={{ ml: 'auto' }}>
            <FontAwesomeIcon icon={faArrowRightToBracket} />
        </IconButton>
    </>
})

export default LoggedUserContainer