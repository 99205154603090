import moment from "moment";
import { unmask } from "../helpers";

moment.locale('pt-br')

const rules = {
    minChar: { 
        check: (value, ruleValue) => value.length >= ruleValue,
        msg: (value, ruleValue) => `Necessário no mínimo ${ruleValue} caracteres`,
    },
    isFilled: {
        check: (value) => value !== null,
        msg: () => ' Campo necessário',
    },
    isDate: {
        check: (value) => moment(value,'DD-MM-YYYY').isValid(),
        msg: () => ' Data informada está invalida',
    }, 
    isTime: { 
        check: (value) => {
            if(!(/\d\d(h|:)\d\d/g.exec(value))) return false 
            
            const minutes = parseInt(value.split( value.includes(':') ? ':' : 'h' )[1]);

            if( minutes > 60 || minutes < 0 ) return false

            return true
        }, 
        msg: () => ' Campo de tempo invalido'
    },
    isInt: {
        check: (value) => parseInt(value),
        msg: () => ' Campo deve ser um número'
    }
}

const converters = { 
    toCents: (value) => parseInt(value.replace(/\D/g, ''))
}

class FormValidator {
    constructor(formState){ 
        if(formState){
            this.form = formState[0]
            this.setForm  = formState[1]
        }
    }

    setFormState(formState){
        this.form = formState[0]
        this.setForm  = formState[1]
    }

    get hasFormState(){ 
        return !!this.form && !!this.setForm
    }

    validate(){
        const keys = Object.keys(this.form)
        const fieldErrors = []
        
        for( const key of keys ){
            const field = this.form[key]

            const errorStack = FormValidator.validateField(field);
            
            if(errorStack)
                fieldErrors.push([ key , errorStack]);
        }

        return fieldErrors.length > 0 ? Object.fromEntries(fieldErrors) : null
    }

    static validateField( field ){
        if(!field.rules) return null

        const keys = Object.keys(field.rules);
        const errorStack = [];

        for(const key of keys){ 
            if( !rules[key].check(field.value, field.rules[key] ) )
                errorStack.push(rules[key].msg(field.value, field.rules[key] ))
        }

        return errorStack.length > 0 ? errorStack : null
    }

    sendFormat(){
        const formValues = {}
        
        for( const key in this.form ){
            let value =  this.form[key].mask ? unmask(this.form[key].value) : this.form[key].value 

            if(this.form[key].converter) value = converters[this.form[key].converter](value)

            formValues[key] = value
        }

        return formValues
    }
} 

export default FormValidator; 

