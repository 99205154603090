import { Alert, Grid, Typography } from "@mui/joy";


export default function AlertFormErrors({ errors }){

    return <Alert color="danger" >
        <Grid container >
            <Grid >
                <Typography fontSize="lg" fontWeight="lg" >Erros: </Typography>
            </Grid>
            {
                Object.keys(errors).map( key => 
                    <Grid xs={12} >  <Typography> <Typography fontWeight="lg" > {key}: </Typography> { errors[key] } </Typography> </Grid>
                )
            }
        </Grid>
    </Alert>
}