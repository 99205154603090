import { deleteCart, postCart } from "../requests/cart"

const key = 'cart.local'

class CartLocalStorage {

    update(product, quantity){
        let localCart = JSON.parse(localStorage.getItem(key))

        if(!localCart) localCart = []

        const index = localCart.findIndex( item => item.product._id === product._id )

        if(index !== -1 ){
            localCart[index].quantity = quantity
        }else{ 
            localCart.push({ 
                product, 
                quantity, 
                total: product.price * quantity
            })
        }

        console.log(localCart)

        localStorage.setItem(key, JSON.stringify(localCart))
    }

    delete(productId){
        
        const localCart = JSON.parse(localStorage.getItem(key))

        const index = localCart.findIndex( (item) => item.product._id === productId ) 

        localCart.splice(index,1)

        localStorage.setItem(key, JSON.stringify(localCart))
    }

    static get(){ 
        return JSON.parse(localStorage.getItem(key)) || []
    }

    transferTo(otherStorage){ 
        const local = JSON.parse(localStorage.getItem(key)) || []

        local.forEach( item =>{
            otherStorage.update(item.product, item.quantity)
        })

        localStorage.setItem(key, JSON.stringify([]))
    }
}

class CartRemoteStorage { 
    update(product, quantity){ 
        return postCart(product._id, quantity)
    }

    delete(productId){ 
        return deleteCart(productId)
    }

    static get(){ 

    }
}

export { CartLocalStorage, CartRemoteStorage }