export const categoriesOfProduct = {
    "Agrícolas": [
        "Grãos",
        "Frutas",
        "Verduras",
        "Legumes",
        "Cereais",
        "Tubérculos"
    ],
    "Pecuários": [
        "Carne bovina",
        "Carne suína",
        "Leite",
        "Ovos",
        "Mel"
    ],
    "Agroindustriais": [
        "Açúcar",
        "Farinha",
        "Óleos vegetais",
        "Conservas"
    ],
    "Florestais": [
        "Madeira",
        "Carvão",
        "Lenha",
        "Resinas",
        "Óleos essenciais"
    ],
    "Artesanais": [
        "Cestarias",
        "Cerâmicas",
        "Bordados",
        "Tecidos"
    ],
    "Naturais": [
        "Plantas medicinais",
        "Cosméticos naturais",
        "Alimentos orgânicos"
    ]
};

export const categoriesList = Object.entries(categoriesOfProduct).reduce((acc, cur)=> acc.concat(cur[1]),[])