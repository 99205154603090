import { CircularProgress, Grid } from "@mui/joy";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import OrderCard from "../components/cards/OrderCard";

export default function MyOrdersPage(){
    const { response } = useLoaderData()
    
    const [orders, setOrders] = useState(null)

    response.then( res =>{
        setOrders(res.data)
    })

    if(!orders){
        return <Grid container justifyContent="center" alignItems="center" minHeight="90%" >
            <CircularProgress size="lg" />
        </Grid>
    }

    return <Grid container spacing={0.5} >
        {
            orders.map( order =>{
                return <Grid key={order._id} xs={12} md={4} > <OrderCard order={order} /> </Grid>
            })
        }
    </Grid>
}